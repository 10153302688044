import { ref } from 'vue';
import bookingRepo from '~/repositories/booking';

export const useBooking = () => {
  const loading = ref(false);
  const charterFormData = reactive({
    location: '',
    start_date_time: '',
    to_date_time: '',
    pax: 1,
  });
  const buyPass = async (data: any) => {
    try {
      return await bookingRepo.buyPass(data);
    //   if (res.status === 'success') {
    //     portList.value = res.data;
    //     loading.value = false;
    //   } else {
    //     showError(res.message);
    //     loading.value = false;
    //   }
      // return await listingRepo.getList();
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const verifyPassPayment = async (data: any) => {
    try {
      return await bookingRepo.verifyPassPayment(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const bookParcel = async (data: any) => {
    try {
      return await bookingRepo.bookParcel(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const verifyParcelPayment = async (data: any) => {
    try {
      return await bookingRepo.verifyParcelPayment(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const verifyPassCode = async (data: any) => {
    try {
      return await bookingRepo.verifyPassCode(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const bookCharter = async (data: any) => {
    try {
      return await bookingRepo.bookCharter(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const verifyCharterPayment = async (data: any) => {
    try {
      return await bookingRepo.verifyCharterPayment(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const bookSeats = async (data: any) => {
    try {
      return await bookingRepo.bookSeats(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const verifyTicketPayment = async (data: any) => {
    try {
      return await bookingRepo.verifyTicketPayment(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const applyCouponCode = async (data: any) => {
    try{
      return await bookingRepo.applyCouponCode(data);
    } catch (error: any) {
      console.log(error);
    }
  }

  const applyPass = async (data: any) => {
    try{
      return await bookingRepo.applyPass(data);
    } catch (error: any) {
      console.log(error);
    }
  }

  const applyVehiclePass = async (data: any) => {
    try{
      return await bookingRepo.applyVehiclePass(data);
    } catch (error: any) {
      console.log(error);
    }
  }
  const cancelTrip = async (data: any) => {
    try {
      return await bookingRepo.cancelTrip(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const guestLogin = async (data: any) => {
    try {
      return await bookingRepo.guestLogin(data);
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  return {
    loading,
    charterFormData,
    buyPass,
    bookParcel,
    verifyPassPayment,
    verifyParcelPayment,
    verifyPassCode,
    bookCharter,
    verifyCharterPayment,
    bookSeats,
    verifyTicketPayment,
    applyCouponCode,
    applyPass,
    applyVehiclePass,
    cancelTrip,
    guestLogin
  };
};