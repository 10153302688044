import { api } from "~/http/Api";

export default {
  buyPass: (data: any) => api.post(`buy-pass`, data, null, true),
  verifyPassPayment: (data: any) => api.post(`verify-pass-payment`, data, null, true),
  bookParcel: (data: any) => api.post(`book-parcel`, data, null, true),
  verifyParcelPayment: (data: any) => api.post(`verify-parcel-payment`, data, null, true),
  verifyPassCode: (data: any) => api.post(`verify-passCode`, data, null, false),
  bookCharter: (data: any) => api.post(`book-charter`, data, null, true),
  verifyCharterPayment: (data: any) => api.post(`verify-charter-payment`, data, null, true),
  verifyTicketPayment: (data: any) => api.post(`verify-payment`, data, null, true),
  bookSeats: (data: any) => api.post(`book-seats`, data, null, true),
  applyCouponCode: (data: any) => api.post(`apply-coupon-code`, data, null, true),
  applyPass: (data: any) => api.post(`apply-pass`, data, null, true),
  applyVehiclePass: (data: any) => api.post(`apply-vehicle-pass`, data, null, true),
  cancelTrip: (data: any) => api.post(`trip-cancelation`, data, null, true),
  guestLogin: (data: any) => api.post(`guest-login`,data,null,false)
};